import done from "../../assets/img/hand.jpg";
import primary from "../../assets/img/serv1.jpeg";
import prepare from "../../assets/img/serv2.jpg";
import high from "../../assets/img/serve3.jpg";
import higher from "../../assets/img/serv.webp";
import higher2 from "../../assets/img/serv4.jpg";

export const packages = [
    {
      img: primary,
      name: " تمريض منزلى",
      describtion:
        "التمريض المنزلي هو خدمة صحية توفر الرعاية الطبية للمرضى في منازلهم، مما يسهم في تعزيز راحتهم وتسريع شفائهم. تشمل هذه الخدمات تقديم الأدوية، تغيير الضمادات، متابعة الحالات الصحية، وتقديم الدعم النفسي للمريض وأسرته",
      green: done,
      services: [
        {
          id: 1,
          desc: "إعداد وجبات منتظمة من الطعام المُغذّي والصحّي وتقديمها للشخص المريض.",
        },
        {
          id: 2,
          desc: "اصطحاب المريض لزيارة الطبيب في المواعيد المقرّرة أو عند اللزوم.",
        },
        { id: 3, desc: "تقديم الدعم العاطفي والنفسي للمريض." },
        {
          id: 4,
          desc: "مساعدة المريض في تغيير الضمادات وتنظيف الجروح في حال وجودها.",
        },
        {
          id: 5,
          desc: "يتم تقديم الرعاية والدعم للأشخاص الذين يعانون من مشاكل في الجهاز التنفسي، مثل استخدام الأكسجين والأجهزة المساعدة للتنفس.",
        },
      ],
    },
    {
      img: prepare,
      name: " رعايه كبار السن بالمنزل و التعامل مع المسنين",
      describtion:
        "رعاية كبار السن في المنزل هي خدمة تقدم للأشخاص المسنين داخل بيوتهم أو محيطهم المألوف، وتهدف هذه الخدمة إلى توفير الرعاية والراحة للمسنين وتلبية احتياجاتهم اليومية  .",
      green: done,
      services: [
        {
          id: 1,
          desc: "يتضمن ذلك توفير الرعاية الطبية الأساسية للمسنين مثل تناول الدواء بانتظام.",
        },
        {
          id: 2,
          desc: "يُساعد المسنين على تناول وجبات صحية متوازنة ومغذية وفقًا لاحتياجاتهم الغذائية الخاصة، بما يشمل إعداد الوجبات وتقديمها لهم",
        },
        {
          id: 3,
          desc: "يتمتع المسنون بالرعاية في النظافة الشخصية والاهتمام بنظافة مناطقهم الحساسة والمهمة",
        },
      ],
    },
    {
      img: high,
      name: "علاج طبيعى",
      describtion:
        "العلاج الطبيعي هو فن وعلم يهدف إلى استعادة وتحسين وصون القدرة على الحركة والوظيفة الجسدية للأشخاص الذين يعانون من إصابات أو أمراض أو إعاقات مختلفة",
      green: done,
      services: [
        {
          id: 1,
          desc: "يتم تقديم التقييم الشامل للحالة الصحية للمريض، وتحديد المشكلات والتحديات التي يواجهها، وتحديد الأهداف التي يجب تحقيقها من خلال العلاج الطبيعي.",
        },
        {
          id: 2,
          desc: "يتم تعليم المرضى حول الوضع الصحي الخاص بهم، وكيفية الحفاظ على التحسينات التي تحققوها خلال العلاج، وكيفية تجنب إعادة حدوث الإصابات.",
        },
        {
          id: 3,
          desc: "يتم تقديم المريض خلال فترة العلاج، وضبط البرنامج العلاجي إذا لزم الأمر، وتقديم التقييم المستمر لتحقيق النتائج المرجوة",
        },
      ],
    },
    {
      img: higher,
      name: "رعايه مرضى الالزهايمر",
      describtion:
        "رعاية مرضى الزهايمر تتطلب فهماً عميقاً لاحتياجات الفرد المصاب بهذا المرض الذي يتطور تدريجياً ويؤثر على القدرة على الحفاظ على الذاكرة والوظائف العقلية الأخرى",
      green: done,
      services: [
        {
          id: 1,
          desc: " توفير بيئة مألوفة وآمنة للمريض، تشمل الحماية من المخاطر المحتملة مثل السقوط والإصابات، وتوفير ترتيب وديكور يسهل التعرف عليه",
        },
        {
          id: 2,
          desc: "    الدعم العاطفي والاجتماعي لمساعدتهم في التعامل مع تغيرات شخصيتهم وسلوكهم، وكذلك دعم أفراد العائلة والمقربين",
        },
        {
          id: 3,
          desc: "  توفير المساعدة في النشاطات اليومية مثل الأكل والشرب والاستحمام والتلبيس، بالإضافة إلى تقديم التدريب لتعليم أساليب جديدة للتعامل مع التحديات اليومية.",
        },
      ],
    },
    {
      img: higher2,
      name: "مهام فريق رعاية المسنين",
      describtion:
        "فريق رعاية المسنين يضم مجموعة متنوعة من المهنيين المتخصصين الذين يعملون سويًا لتوفير الرعاية والدعم الشامل للمسنين",
      green: done,
      services: [
        {
          id: 1,
          desc: "يقومون بتقديم الرعاية الصحية الشاملة للمسنين، بما في ذلك التشخيص والعلاج وإدارة الأمراض المزمنة. يراقبون حالة الصحة للمسنين ويقدمون العناية اللازمة في حالة الطوارئ.",
        },
        {
          id: 2,
          desc: "يوفرون الدعم العاطفي والاجتماعي للمسنين وعائلاتهم. يقومون بتقديم المشورة والتوجيه للتعامل مع التحديات الاجتماعية والعاطفية التي قد يواجهونها",
        },
        {
          id: 3,
          desc: "يقدمون المساعدة في النشاطات اليومية مثل الأكل والشرب والاستحمام والتلبيس. يوفرون رعاية شخصية مستمرة للمسنين.",
        },
      ],
    },
  ];
export const headerData = [
  {
    id: 1,
    name: "الرئيسيه",
    link: "الرئيسيه",
  },
  {
    id: 2,
    name: "خدماتنا",
    link: "خدماتنا",
  },
  {
    id: 3,
    name: "اهتمامنا",
    link: "اهتمامنا",
  },
  {
    id: 4,
    name: "ما يميزنا",
    link: "ما_يميزنا",
  },
  {
    id: 5,
    name: "تعرف علينا",
    link: "تعرف_علينا",
  },
  {
    id: 6,
    name: "للتواصل معنا والحجز",
    link: "للتواصل_معنا_والحجز",
  },
];
